<template>
    <ValidationObserver
        tag="form"
        ref="observer"
        class="new-comment"
        @submit.prevent="onSubmit"
    >
        <div class="new-comment__row">
            <router-link
                to="/auth/profile/"
                class="new-comment__col new-comment__col--left"
            >
                <avatar :src="current_user.image_zoom_out"/>
            </router-link>

            <ValidationProvider
                class="new-comment__col new-comment__col--center new-comment__wrap"
                tag="div"
                vid="comment_text"
                v-slot="{errors}"
                :rules="`required`"
            >
                <textarea-autosize
                    rows="1"
                    ref="newComment"
                    placeholder="Комментировать"
                    :class="[
                        'new-comment__textarea',
                        { 'new-comment__textarea--active': comment_text.length }
                    ]"
                    :maxlength="1024"
                    @keyup.enter.ctrl.native="comment_text += '\n'"
                    @keypress.enter.native.exact="onSubmit($event)"
                    v-model="comment_text"
                />
                <span class="v-error animated fadeInDown" v-if="errors.length">
                    {{errors[0]}}
                </span>
            </ValidationProvider>

            <div class="new-comment__col new-comment__col--right">
                <button
                    v-if="value ? value !== comment_text : comment_text.length"
                    type="button"
                    class="new-comment__trigger new-comment__submit empty-btn"
                    v-tooltip="'Отправить'"
                    :disabled="isCommentSending"
                    @click="onSubmit"
                />

                <button
                    v-if="isCancel"
                    type="button"
                    class="new-comment__trigger new-comment__cancel empty-btn"
                    v-tooltip="`Отменить`"
                    @click="onCancel"
                />
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
    import { mapState } from 'vuex';
    import session from '@/api/session';

    export default {
        name: 'NewComment',
        components: {
            Avatar: () => import('@/components/Avatar')
        },
        props: {
            objId: {
                type: Number,
                required: true
            },
            onAfterComment: {
                type: Function,
                required: false
            },
            contentType: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: false,
                default: ''
            },
            commentTo: {
                type: Object,
                required: false
            },
            parentId: {
                type: Number,
                required: false
            },
            isCancel: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                comment_text: this.value,
                isCommentSending: false
            };
        },
        computed: {
            ...mapState('default_data', [
                'current_user', 'limits'
            ])
        },
        methods: {
            async onSubmit(e) {
                e.preventDefault();

                this.isCommentSending = true;

                const isValid = await this.$refs.observer.validate();

                if (isValid) {
                    const comment = {
                        text: this.comment_text,
                        content_type: this.contentType,
                        object_id: this.objId
                    };

                    if (this.commentTo) {
                        comment.parent = this.parentId;
                        comment.answer_to = this.commentTo.id;
                    }

                    try {
                        const request = await session.post('/api/v1/comment/', comment);
                        this.onAfterComment(request.data);
                        this.comment_text = '';
                        this.$refs.observer.reset();
                    } catch (e) {
                        this.$swal('Ошибка!', 'При отправке комментария произошла ошибка на сервере', 'error');
                        console.error(e);
                    }

                    this.isCommentSending = false;
                }
            },

            onCancel(event) {
                this.$emit('cancel', this.comment_text)
            }
        },
        created() {
            if (this.commentTo) {
                this.$nextTick(() => this.$refs.newComment.$el.focus());
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';

    .new-comment {
        $p: &;

        width: 100%;
        margin-top: 15px;
        &__row {
            margin: -10px;
            display: flex;
        }

        &__col {
            margin: 10px;
            display: flex;
            &--right {
                align-self: center;
            }
        }

        &__wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &__textarea {
            border: none;
            resize: none;
            outline: none;
            width: 100%;
            padding: 10px 20px;
            &::placeholder {
                font-size: 1rem;
                color: $text-gray;
            }

            &--active {
                border: 1.5px solid $gold;
            }
        }

        &__trigger {
            cursor: pointer;
            align-self: center;
            &:active {
                opacity: 0.7;
            }

            & + #{$p}__trigger {
                margin-left: 10px;
            }
        }

        &__submit {
            @include icon($send-comment-icon, 25px);
        }

        &__cancel {
            @include icon($close-icon-red, 20px);
        }
    }
</style>
